<template>
  <div class="notice">
    <scrollview
      @onRefresh="onRefresh"
      @onReload="onReload"
      :height="scrollHeight"
    >
      <template v-if="backGoodsList.length > 0">
        <div
          class="site-points"
          v-for="(site, siteIndex) in backGoodsList"
          :key="'site-points-' + siteIndex"
        >
          <div class="site-info">
            <div class="site-name">{{ site.site_name }}</div>
            <div class="back-number">
              {{ site.goods.length + site.after_sale_goods.length }}种商品
            </div>
          </div>
          <div class="site-contact-info">
            <div class="site-leader">负责人：{{ site.site_leader }}</div>
            <div class="site-mobile">
              联系电话：{{ site.site_leader_mobile }}
            </div>
          </div>
          <div class="goods-list">
            <div
              class="goods"
              v-for="(goods, goodsIndex) in site.goods"
              :key="'goods-' + siteIndex + '-' + goodsIndex"
            >
              <div class="cover">
                <img :src="goods.cover" />
              </div>
              <div class="goods-info">
                <div class="title">{{ goods.title }}</div>
                <div class="spec-text">
                  规格：{{ goods.spec_text ? goods.spec_text : "默认规格" }}
                  <span
                    style="color: #108ee9; font-weight: bold; margin-left: 20px"
                    >× {{ goods.number }}</span
                  >
                </div>
                <div class="back-reason">
                  原因：<span>{{ goods.back_reason }}</span>
                </div>
              </div>
            </div>

            <div
              class="goods"
              v-for="(goods, goodsIndex) in site.after_sale_goods"
              :key="'goods-' + siteIndex + '-' + goodsIndex"
            >
              <div class="cover">
                <img :src="goods.cover" />
              </div>
              <div class="goods-info">
                <div class="title">{{ goods.title }}</div>
                <div class="spec-text">
                  规格：{{ goods.spec_text ? goods.spec_text : "默认规格" }}
                  <span
                    style="color: #108ee9; font-weight: bold; margin-left: 20px"
                    >× {{ goods.number }}</span
                  >
                </div>
                <div class="back-reason">
                  原因：<span>{{ goods.back_reason }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </scrollview>
  </div>
</template>
<script>
export default {
  name: "back-goods",
  data() {
    return {
      backGoodsList: [],
      scrollHeight: 0,
      page: 1,
      pageSize: 10,
      show: false,
    };
  },
  mounted() {
    document.title = "追回商品列表";
    this.scrollHeight = window.innerHeight;
    console.log("this.scrollHeight", this.scrollHeight);
    this.getBackGoods();
  },
  methods: {
    onRefresh(done) {
      this.backGoodsList = [];
      this.page = 1;
      this.getBackGoods().finally(() => {
        done();
      });
    },
    onReload(step, over) {
      this.page += 1;
      this.getBackGoods().finally(() => {
        if (this.backGoodsList.length < this.page * this.pageSize) {
          over();
        } else {
          step();
        }
      });
    },
    getBackGoods() {
      let params = {
        page: this.page,
        pageSize: this.pageSize,
      };
      this.$api.community.courier.getBackGoodsList(params).then((response) => {
        this.backGoodsList = response.data;
      });
    },
    showAction() {
      this.show = true;
    },
    handleAction() {},
  },
};
</script>

<style scoped lang="scss">
.notice {
  background: #f6f6f6;
}

.site-points {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  background: #fff;
  border-radius: 10px 10px 0 0;
  padding-bottom: 5px;

  .site-info {
    flex: 1;
    display: flex;
    align-items: center;
    height: 48px;
    background: linear-gradient(129deg, #fbf2e2 0%, #f1e2c9 100%);
    border-radius: 10px 10px 0px 0px;
    padding: 10px;
    color: #6c4806;

    .site-name {
      flex: 1;
      font-size: 16px;
    }

    .back-number {
      width: 80px;
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
    }
  }

  .site-contact-info {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 5px 10px;

    .site-leader {
      font-size: 14px;
      color: #999;
    }

    .site-mobile {
      font-size: 14px;
      color: #999;
      margin-top: 10px;
      padding-bottom: 10px;
      border-bottom: #f1f1f1 dashed 1px;
    }
  }

  .goods-list {
    display: flex;
    flex-direction: column;

    .goods {
      flex: 1;
      display: flex;
      margin-bottom: 5px;
      padding: 5px;
      margin-left: 10px;
      margin-right: 10px;
      border: #f1f1f1 dashed 1px;
      .cover {
        width: 60px;
        height: 60px;
        border-radius: 5px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .goods-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        margin-top: 5px;
        position: relative;

        .title {
          font-size: 16px;
          color: #333;
        }

        .spec-text {
          font-size: 14px;
          color: #999;
        }

        .back-reason {
          font-size: 14px;
          color: #999;
        }

        .goods-actions {
          position: absolute;
          right: 0;
          top: 0;
          padding: 0 15px;
          background: red;
          box-shadow: 0 4px 36px 0 rgba(221, 221, 221, 0.65);
          border-radius: 3px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #fff;
          display: flex;
          align-items: center;
          height: 32px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.confirm-window {
  display: flex;
  flex-direction: column;

  .title {
    flex: 1;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.6);
    padding: 15px 0;
  }

  .content {
    font-size: 16px;
    color: #333;
    padding: 10px 20px;
    line-height: 25px;
    display: flex;
    flex-direction: column;

    .site-info {
      flex: 1;
      border: #ccc dashed 1px;
      padding: 5px 10px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      background: #fff9ec;
      color: #333;

      .site-name {
        font-size: 16px;
      }

      .site-leader {
        font-size: 14px;
        color: #999;
      }
    }

    .qrcode {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      img {
        width: 80%;
      }
    }

    .warning {
      padding: 20px 20px 10px 20px;
      text-indent: 20px;
      font-size: 14px;
      color: #999;
    }
  }
}

.buttons {
  background: #fff;
  height: 50px;
  padding: 10px 50px;
  display: flex;

  .btn {
    flex: 1;
    bottom: calc(30px + env(safe-area-inset-bottom));
    bottom: calc(30px + constant(safe-area-inset-bottom));
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(139deg, #fac484 0%, #f46a17 100%);
    background-clip: padding-box;
    border: 4px solid rgba(244, 106, 23, 0.15);
    border-radius: 29px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
